export const menuItems = [
 
    {
        id: 1,
        label: "menuitems.dashboard.text",
        icon: "uil-home-alt",
        link: "/"
    },
    // {
    //     id: 2,
    //     label: "Area & Role",
    //     icon: "uil-house-user",
    //     subItems: [
    //         {
    //             id: 1,
    //             label: "Create Area with Role",
    //             link: "/workarea/create",
    //             parentId: 2
    //         },
    //         {
    //             id: 2,
    //             label: "Listing",
    //             link: "/workarea/listing",
    //             parentId: 2
    //         },
         
    //     ]
    // },

    {
        id:3,
        label: "Shift",
        icon: "mdi mdi-pencil-minus-outline",
        subItems: [
            {
                id: 7,
                label: "Add Shift Name",
                link: "/shift-names",
                parentId: 3
            },
            {
                id: 1,
                label: "Create Shift",
                link: "/create-shift",
                parentId: 3
            },
            {
                id: 2,
                label: "Create Weekly Shift",
                link: "/create-weekly-shift",
                parentId: 3
            },
            {
                id: 3,
                label: "Shift Listing",
                link: "/shifts",
                parentId: 3
            },
            {
                id: 4,
                label: "Shift Requests",
                link: "/business-owner-shift-listing",
                parentId: 3
            },

            {
                id:6,
                label: "Shifts Roster",
                // icon: "uil-calender",
                link: "/shifts-roster",
                parentId: 3
            },
         
        ]
    },
    // {
    //     id:4,
    //     label: "Team Members",
    //     icon: "uil-users-alt",
    //     subItems: [
    //         {
    //             id: 1,
    //             label: "New Employee Requests",
    //             link: "/team-member-requests",
    //             parentId: 4
    //         },
    //         {
    //             id: 2,
    //             label: "All Active Staff Members",
    //             link: "/team-member-record",
    //             parentId: 4
    //         },
    //         {
    //             id: 3,
    //             label: "Former Staff Members",
    //             link: "/former-team-member-record",
    //             parentId: 4
    //         },
    //         {
    //             id: 4,
    //             label: "Add User/Staff",
    //             link: "/create-team-member",
    //             parentId: 4
    //         },
    //         {
    //             id: 5,
    //             label: "All Managers",
    //             link: "/managers-listing",
    //             parentId: 4
    //         },
    //         // {
    //         //     id: 6,
    //         //     label: "All Admin Users",
    //         //     link: "/create-team-member",
    //         //     parentId: 4
    //         // },
         
    //     ]
    // },
    // {
    //     id: 5,
    //     label: "Manager Users ",
    //     icon: "mdi mdi-account-cog-outline",
    //     subItems: [
    //         {
    //             id: 1,
    //             label: "User Listing",
    //             link: "/managers-listing",
    //             parentId: 5
    //         },
    //         {
    //             id: 2,
    //             label: "Create Manager",
    //             link: "/create-manager",
    //             parentId: 5
    //         },
         
    //     ]
    // },
    // {
    //     id: 6,
    //     label: "Locations",
    //     icon: "mdi mdi-map-marker-outline",
    //     subItems: [
    //         {
    //             id: 14,
    //             label: "Location Listing",
    //             link: "/locations",
    //             parentId: 6
    //         },
    //         {
    //             id: 15,
    //             label: "Create Location",
    //             link: "/create-location",
    //             parentId: 6
    //         },
         
    //     ]
    // },
  

   
    {
        id:8,
        label: "Calendar",
        icon: "uil-calender",
        link: "/calendar",
    },
    {
        id: 9,
        label: "Settings",
        icon: "uil-setting",
        subItems: [
            {
                id:7,
                label: "My Profile",
                link: "/my-profile"
            },
            {
                id: 16,
                label: "Location Listing",
                link: "/locations",
                parentId: 6
            },
            {
                id: 17,
                label: "Create Location",
                link: "/create-location",
                parentId: 6
            },
            {
                id: 1,
                label: "New Employee Requests",
                link: "/team-member-requests",
                parentId: 4
            },
            {
                id: 2,
                label: "All Active Staff Members",
                link: "/team-member-record",
                parentId: 4
            },
            {
                id: 3,
                label: "Former Staff Members",
                link: "/former-team-member-record",
                parentId: 4
            },
            {
                id: 4,
                label: "Add User/Staff",
                link: "/create-team-member",
                parentId: 4
            },
            {
                id: 5,
                label: "All Managers",
                link: "/managers-listing",
                parentId: 4
            },
            {
                id: 1,
                label: "Create Area with Role",
                link: "/workarea/create",
                parentId: 2
            },
            {
                id: 2,
                label: "Work Area Listing",
                link: "/workarea/listing",
                parentId: 2
            },
        ]
    },
    
    {
        id: 11,
        label: "Reports",
        icon: "uil-clipboard",
        subItems:[
            {
                id:1,
                label: "Shift Per Employee",
                link: "/shifts-per-employee"
            },{
                id:1,
                label: "Weekly Report",
                link: "/weekly-report"
            }
        ]
    },
    {
        id: 10,
        label: "Tickets",
        icon: "uil-ticket",
        link: "/tickets"
    },
    {
        id: 12,
        label: "Labor Cost",
        icon: "uil-dollar-alt",
        link: "/employee-payroll"
    },
  
]