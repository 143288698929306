<script>
import {
    layoutComputed
} from "@/state/helpers";
// import Vertical from "./vertical";
import Horizontal from "./horizontal";

/**
 * Main Layout
 */
export default {
    props:{
        dynamicClass:{
            type:String,
            default:"container"
        }
    },
    components: {
        // Vertical,
        Horizontal,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    mounted() {
        document.body.classList.remove('authentication-bg')
    },
    methods: {},
};
</script>

<template>
<div>
    <!-- <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
        <slot />
    </Vertical> -->

    <Horizontal  :layout="layoutType" :dynamicClass="dynamicClass">
        <slot />
    </Horizontal>
</div>
</template>
